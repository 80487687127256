import React from "react";
import { Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <Row className="p-5 m-0">
        <h4 className="text-center m-0">tipmaid Privacy Policy</h4>
        <p className="mt-2 mb-5 text-center">Last Updated: 05/2024</p>
        <h5>Introduction</h5>
        <p>
          Welcome to tipmaid. This Privacy Policy explains how tipmaid ("we",
          "us", or "our") collects, uses, and discloses information about you
          when you access or use our web-based chatbot service for tipping hotel
          staff and otherwise interact with us.
        </p>
        <h5>Acceptance of Policy</h5>
        <p>
          By using tipmaid, you agree to the collection, use, and sharing of
          your information as described in this Privacy Policy.
        </p>
        <h5>Information Collection</h5>
        <p>
          We collect information you provide directly to us, such as the name of
          the hotel staff you wish to tip and the amount. We also collect
          aggregated usage data for service improvement purposes.
        </p>
        <h5>Use of Information</h5>
        <p>
          The information you provide is used primarily to process your tips
          through our payment providers, Dock and Stripe. We may also use your
          information to communicate with you about our service and address any
          issues. Client gives us permission to act on behalf of the client to
          access accounts built and maintained on our system, or validate
          transactions requested by Client. At initial setup of the account, the
          company is impersonating the user account for proper setting. After
          the account is established and Client takes ownership of the account,
          we will access the account only at client request with client
          approval.
        </p>
        <h5>Third-party Integration</h5>
        <p>
          Information necessary for processing payments is shared with Dock and
          Stripe. Their use of your information is governed by their respective
          privacy policies.
        </p>
        <h5>Cookies and Tracking</h5>
        <p>
          Our service may use cookies for optimal functionality. You have the
          option to disable cookies in your browser settings, but this may
          affect your user experience.
        </p>
        <h5>Data Security</h5>
        <p>
          We take appropriate measures to protect your data from unauthorized
          access and data breaches.
        </p>
        <h5>User Rights</h5>
        <p>
          You may inquire about the data we hold about you and request
          corrections or deletions as applicable.
        </p>
        <h5>Changes to This Policy</h5>
        <p>
          We may update this policy from time to time. We will notify users of
          any significant changes.
        </p>
        <h5>Contact Us</h5>
        <p>
          For any questions or concerns about this policy, please contact us at
          support@tipmaid.io
        </p>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
